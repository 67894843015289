<template>
  <div>
    <section class="seccao header-fixo" id="produto-wrapper">
      <Header
        :title="product_data.area_name"
        :breadcrums="breadcrums"
        :cartItems="cartItems"
      />
      <div v-if="showModal == true">
        <div v-if="!isLogged" id="popup">
          <button id="show-btn" class="buttonpop"></button>
          <div class="modal-overlay"></div>
          <transition name="slide" appear>
            <div class="modal">
              <v-btn class="close-popup" @click="closeModal"> X </v-btn>
              <h2
                data-v-736e7094=""
                id="intro"
                class="title-with-strap green-strap black after-popup"
              >
                Olá
              </h2>
              <h5 class="popup-second-title">
                Faça
                <router-link
                  class="green font-weight-600 login-popup"
                  style="text-decoration: none"
                  to="/login"
                  >Login</router-link
                >
                <br />
                Para Saber Mais
              </h5>
              <div class="button-wrapper">
                <router-link to="/login">
                  <button
                    id="button-submit"
                    href="/login"
                    class="button diagonal ghost font-weight-500 text-uppercase color:rgb(46,183,142)"
                    style="color: rgb(46, 183, 142)"
                  >
                    Entrar
                  </button></router-link
                >
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="nav-fixed">
        <nav>
          <div class="menu-container swiper-container">
            <div class="menu swiper-wrapper">
              <div
                :key="'/categoria/' + item.id"
                v-for="item in menu"
                class="swiper-slide"
              >
                <div
                  :class="[
                    product_data.category_id === item.id ? 'active' : '',
                    'menu-item',
                  ]"
                >
                  <router-link
                    class="links"
                    :key="'/categoria/' + item.id"
                    :to="{ path: '/categoria/' + item.id }"
                    >{{ item.name }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="nav-swiper-button-next"></div>
          <div class="nav-swiper-button-prev"></div>
        </nav>
      </div>
    </section>
    <div id="produto" v-if="!removido">
      <section class="seccao header-fixo" id="produto-wrapper">
        <main class="smaller">
          <div class="main-inner-wrapper">
            <div class="main-coluna-conteudo sem-padding">
              <div class="main-coluna-conteudo-inner-wrapper">
                <h2 class="title-with-strap">
                  {{ page_data.name }}
                  <img
                    v-if="page_data.discount_price > 0"
                    class="discount-tag"
                    src="/assets/fotos_catalogo/products/tags.png"
                    style="max-width: 30px"
                  />
                </h2>
                <h3 class="h2">{{ page_data.open_sentence }}</h3>
                <div class="font-size-24 font-weight-500">
                  {{ page_data.description }}
                </div>
              </div>
            </div>
            <div class="main-coluna-imagens" id="swiper_produtos">
              <div class="imagens-wrapper swiper-wrapper">
                <div
                  v-if="product_images.video != null"
                  class="swiper-slide video-container"
                >
                  <video class="prod-video" autoplay loop muted>
                    <source
                      :src="
                        '/assets/fotos_catalogo/products/' +
                        product_images.video
                      "
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div
                  v-if="product_images.image_1 != null"
                  class="swiper-slide"
                  :style="{
                    'background-image':
                      'url(/assets/fotos_catalogo/products/' +
                      product_images.image_1 +
                      ')',
                  }"
                ></div>
                <div
                  v-if="product_images.image_2 != null"
                  class="swiper-slide"
                  :style="{
                    'background-image':
                      'url(/assets/fotos_catalogo/products/' +
                      product_images.image_2 +
                      ')',
                  }"
                ></div>
                <div
                  v-if="product_images.image_3 != null"
                  class="swiper-slide"
                  :style="{
                    'background-image':
                      'url(/assets/fotos_catalogo/products/' +
                      product_images.image_3 +
                      ')',
                  }"
                ></div>
                <div
                  v-if="product_images.image_1 == null"
                  class="swiper-slide"
                  :style="{ 'background-image': 'url(' + imgProd + ')' }"
                ></div>
              </div>
              <!-- <div id="prod_top_pagination" class="swiper-pagination"></div> -->
              <div class="slider-swiper-button-next"></div>
            </div>
          </div>
        </main>
        <footer>
          <div
            class="footer-inner-wrapper"
            style="background-color: var(--cor-principal)"
          >
            <div class="container container-75p cor-1">
              <div class="container-inner-wrapper">
                <div v-if="page_data.advantage_1 != ''" class="item">
                  <h3 class="title-with-strap white">
                    {{ page_data.advantage_1 }}
                  </h3>
                  <div class="font-size-16 white">
                    {{ page_data.advantage_desc_1 }}
                  </div>
                </div>
                <div v-if="page_data.advantage_2 != ''" class="item">
                  <h3 class="title-with-strap white">
                    {{ page_data.advantage_2 }}
                  </h3>
                  <div class="font-size-16 white">
                    {{ page_data.advantage_desc_2 }}
                  </div>
                </div>
                <div v-if="page_data.advantage_3 != ''" class="item">
                  <h3 class="title-with-strap white">
                    {{ page_data.advantage_3 }}
                  </h3>
                  <div class="font-size-16 white">
                    {{ page_data.advantage_desc_3 }}
                  </div>
                </div>
                <div v-if="page_data.advantage_4 != ''" class="item">
                  <h3 class="title-with-strap white">
                    {{ page_data.advantage_4 }}
                  </h3>
                  <div class="font-size-16 white">
                    {{ page_data.advantage_desc_4 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="container container-25p white">
              <div class="container-inner-wrapper">
                <div class="item">
                  <h3
                    class="title-with-strap"
                    style="margin-top: 5px; margin-bottom: 15px"
                  >
                    Sabia que
                  </h3>
                  <div class="font-size-16">{{ page_data.trivia }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <a v-scroll-to="'#second'" class="footer-avancar" style="bottom: 0px;display:none" id="bot-scroll-sec"></a>
                    <a v-scroll-to="'#relacionados'" class="footer-avancar" style="bottom: 0px;display:none" id="bot-scroll-rel"></a> -->
        </footer>
      </section>

      <div
        v-if="
          page_data.action_sentence == null || page_data.action_sentence == ''
        "
      >
        <div
          v-if="page_data.discount_price == 0 || page_data.price < 0.02"
          class="mini-btn"
          id="mini-adicionar"
          @click="addProduct(page_data)"
        >
          ADICIONAR
          <!-- <i class="fas fa-shopping-cart"></i> -->
          <img src="@/assets/imagens/icone-carrinho.svg" />
        </div>
        <router-link
          :to="{ path: '/formulario/' + product_id }"
          id="mini-contactar"
          class="mini-btn links"
        >
          CONTACTAR<i class="fas fa-envelope"></i>
        </router-link>
      </div>

      <section
        v-if="
          page_data.action_sentence != null && page_data.action_sentence != ''
        "
        id="second"
        class="seccao seccao-produto header-fixo padding-top-normal-medium-down"
      >
        <!-- <a v-scroll-to="'#produto-wrapper'" class="header-voltar white"></a> -->
        <main>
          <div class="main-inner-wrapper">
            <div class="main-coluna-conteudo sem-padding">
              <div class="main-coluna-conteudo-inner-wrapper">
                <h2 class="title-with-strap">
                  {{ page_data.action_sentence }}
                </h2>
                <div class="lista-icone-descricao-wrapper">
                  <div class="lista-item">
                    <div
                      class="icone-wrapper"
                      style="color: var(--cor-principal)"
                    >
                      <i :class="'fas fa-' + page_data.b_img_1"></i>
                    </div>
                    <div class="descricao-wrapper">
                      <h5>{{ page_data.b_title_1 }}</h5>
                      <div class="font-size-16">{{ page_data.b_desc_1 }}</div>
                    </div>
                  </div>
                  <div class="lista-item">
                    <div
                      class="icone-wrapper"
                      style="color: var(--cor-principal)"
                    >
                      <i :class="'fas fa-' + page_data.b_img_2"></i>
                    </div>
                    <div class="descricao-wrapper">
                      <h5>{{ page_data.b_title_2 }}</h5>
                      <div class="font-size-16">{{ page_data.b_desc_2 }}</div>
                    </div>
                  </div>
                </div>

                <div class="buttons-wrapper">
                  <div>
                    <div class="button-inner-wrapper">
                      <a
                        v-if="
                          page_data.price_info != '' &&
                          page_data.price_info != null
                        "
                        :href="page_data.price_link"
                        class="button padding-right-xl corte-diagonal-right font-size-16 font-weight-500"
                      >
                        {{ page_data.price_info }}
                      </a>
                      <a
                        :href="page_data.price_link"
                        class="button padding-right-xl corte-diagonal-right font-size-16 font-weight-500"
                        v-else
                      >
                        Informação comercial deste artigo
                      </a>
                      <i
                        class="far fa-credit-card"
                        style="color: var(--cor-principal)"
                      ></i>
                    </div>

                    <span
                      v-if="
                        page_data.price > 0 &&
                        (page_data.price_condition == null ||
                          page_data.price_condition == '')
                      "
                      class="nota"
                    >
                      Ao valor apresentado acresce o IVA à taxa em vigor
                    </span>
                    <span v-else-if="page_data.price > 0" class="nota">
                      {{ page_data.price_condition }}
                    </span>
                    <span class="nota" v-else> </span>
                  </div>

                  <!-- <div v-if="item.discount_price > 0" class="produto-preco" style="line-height:23px">
                                        <span style="font-weight: lighter; text-decoration:line-through;color:#666">{{parseInt(item.price).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}}€</span>
                                        <div style="color:var(--cor-2);">{{parseInt(item.discount_price).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}}€</div>
                                    </div> -->

                  <div
                    v-if="page_data.discount_price > 0 && isLogged"
                    class="preco"
                  >
                    <span
                      style="
                        font-weight: lighter;
                        text-decoration: line-through;
                        color: #666;
                      "
                      >{{
                        parseInt(page_data.price)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}€</span
                    >
                    <div style="color: var(--cor-2)">
                      {{
                        parseInt(page_data.discount_price)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}€
                    </div>
                  </div>
                  <div
                    v-else-if="page_data.price > 0 && isLogged"
                    class="preco"
                  >
                    <span v-if="page_data.price > 0" style="width: 100%"
                      >desde</span
                    >
                    <div style="color: var(--cor-principal)">
                      {{
                        parseInt(page_data.price)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}€
                    </div>
                  </div>
                  <div class="preco" v-if="!isLogged">
                    <span>Preço sob consulta</span>
                  </div>
                </div>

                <div class="lista-icone-pq-texto-wrapper">
                  <div
                    v-if="
                      page_data.service_condition_1 != null &&
                      page_data.service_condition_1 != ''
                    "
                    class="lista-item"
                  >
                    <div
                      class="icone-wrapper"
                      style="color: var(--cor-principal)"
                    >
                      <i
                        :class="'fas fa-' + page_data.service_condition_1_img"
                      ></i>
                      <!-- <i class="fas fa-shipping-fast"></i> -->
                    </div>
                    <div class="texto-wrapper font-size-16">
                      {{ page_data.service_condition_1 }}
                    </div>
                  </div>
                  <div
                    v-if="
                      page_data.service_condition_2 != null &&
                      page_data.service_condition_2 != ''
                    "
                    class="lista-item"
                  >
                    <div
                      class="icone-wrapper"
                      style="color: var(--cor-principal)"
                    >
                      <i
                        :class="'fas fa-' + page_data.service_condition_2_img"
                      ></i>
                      <!-- <i class="fas fa-headset"></i> -->
                    </div>
                    <div class="texto-wrapper font-size-16">
                      {{ page_data.service_condition_2 }}
                    </div>
                  </div>
                  <div
                    v-if="
                      page_data.service_condition_3 != null &&
                      page_data.service_condition_3 != ''
                    "
                    class="lista-item"
                  >
                    <div
                      class="icone-wrapper"
                      style="color: var(--cor-principal)"
                    >
                      <i
                        :class="'fas fa-' + page_data.service_condition_3_img"
                      ></i>
                      <!-- <i class="fas fa-shield-alt"></i> -->
                    </div>
                    <div class="texto-wrapper font-size-16">
                      {{ page_data.service_condition_3 }}
                    </div>
                  </div>
                </div>

                <!-- <div class="buttons-wrapper btn-50" v-if="page_data.discount_price > 0 || page_data.price > 0" style="margin-top: 20px">
                                    <div id="btn-cart" class="button font-size-20 font-weight-600" @click="addProduct(page_data)">
                                        <div class="button-txt">
                                            Adicionar ao carrinho
                                            <img id="cart-icon-prod" src="@/assets/imagens/icone-carrinho.svg">
                                        </div>
                                    </div>
                                </div> -->

                <!-- <div class="buttons-wrapper btn-50" style="margin-top: 20px">
                  <div
                    id="btn-cart"
                    class="button font-size-20 font-weight-600"
                    @click="addProduct(page_data)"
                  >
                    <div class="button-txt">
                      Adicionar ao carrinho
                      <i class="fas fa-shopping-cart" id="cart-icon-prod"></i>
                      <img
                        id="cart-icon-prod"
                        src="@/assets/imagens/icone-carrinho.svg"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="main-coluna-imagens">
              <div>
                <div class="imagens-wrapper swiper-wrapper smaller">
                  <div
                    v-if="product_images.image_2 != null"
                    class="swiper-slide"
                    :style="{
                      'background-image':
                        'url(/assets/fotos_catalogo/products/' +
                        product_images.image_2 +
                        ')',
                    }"
                  ></div>
                  <div
                    v-if="product_images.image_3 != null"
                    class="swiper-slide"
                    :style="{
                      'background-image':
                        'url(/assets/fotos_catalogo/products/' +
                        product_images.image_3 +
                        ')',
                    }"
                  ></div>
                  <div
                    v-if="product_images.image_1 != null"
                    class="swiper-slide"
                    :style="{
                      'background-image':
                        'url(/assets/fotos_catalogo/products/' +
                        product_images.image_1 +
                        ')',
                    }"
                  ></div>
                  <div
                    v-if="product_images.video != null"
                    class="swiper-slide video-container"
                  >
                    <video class="prod-video" autoplay loop muted>
                      <source
                        :src="
                          '/assets/fotos_catalogo/products/' +
                          product_images.video
                        "
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    v-if="product_images.image_1 == null"
                    class="swiper-slide"
                    :style="{ 'background-image': 'url(' + imgProd + ')' }"
                  ></div>
                </div>
                <!-- <div id="prod_top_pagination" class="swiper-pagination"></div> -->
                <div class="slider-swiper-button-next"></div>
              </div>

              <div
                class="buttons-wrapper btn-50"
                v-if="documents.length > 0 || documentsAdmin.length > 0"
              >
                <router-link
                  :to="{ path: '/formulario/' + product_id }"
                  class="button padding-left-xl padding-right-xl font-weight-600 font-size-20"
                  id="falar-connosco"
                >
                  <div class="button-txt">Quer falar connosco?</div>
                </router-link>
                <!-- Admin documents -->
                <select
                  v-if="isLogged == 1 || isLogged == 2"
                  id="documentos-a-descarregar"
                  class="font-weight-600 font-size-20"
                  @click="analyticsEventDocumentos()"
                  @change="openInNewTab()"
                  v-model="fileURL"
                >
                  <option class="button-txt" id="saiba-mais" value="" disabled>
                    Documentos
                  </option>
                  <option
                    v-for="doc in documentsAdmin"
                    :key="doc.value"
                    :value="doc.value"
                  >
                    {{ doc.label }}
                  </option>
                </select>
                <!-- Normal user documents -->
                <select
                  v-if="isLogged == 0"
                  id="documentos-a-descarregar"
                  class="font-weight-600 font-size-20"
                  @click="analyticsEventDocumentos()"
                  @change="openInNewTab()"
                  v-model="fileURL"
                >
                  <option class="button-txt" id="saiba-mais" value="">
                    Documentos
                  </option>
                  <option
                    v-for="doc in documents"
                    :key="doc.value"
                    :value="doc.value"
                  >
                    {{ doc.label }}
                  </option>
                </select>
                <div
                  v-if="!isLogged"
                  id="documentos-a-descarregar-login"
                  class="font-weight-600 font-size-20"
                >
                  <router-link to="/login" id="saber-mais"
                    >Faça Login Para Saber Mais</router-link
                  >
                </div>
              </div>

              <div class="buttons-wrapper btn-50" v-else>
                <a
                  href=""
                  onclick="event.preventDefault()"
                  class="button padding-left-xl padding-right-xl font-weight-600 font-size-20 no-pointer"
                  id="falar-connosco"
                >
                  <div class="button-txt">Quer falar connosco?</div>
                </a>
                <router-link
                  :to="{ path: '/formulario/' + product_id }"
                  class="m_link"
                >
                  <button
                    id="documentos-a-descarregar"
                    class="font-weight-600 font-size-20"
                    @click="analyticsEventContactos()"
                  >
                    Solicitar Contacto
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </main>

        <!-- <a v-scroll-to="'#relacionados'" class="footer-avancar"></a> -->
      </section>

      <section
        id="relacionados"
        class="seccao header-fixo padding-top-normal-medium-down"
      >
        <!-- <a v-scroll-to="'#second'" class="header-voltar"></a> -->

        <!-- <a v-scroll-to="'#second'" class="header-voltar white" style="display:none" id="top-scroll-sec"></a>
                <a v-scroll-to="'#produto-wrapper'" class="header-voltar white" style="display:none" id="top-scroll-pro"></a> -->

        <main class="listagem-produtos-com-footer">
          <div class="main-inner-wrapper">
            <div class="main-coluna-100p">
              <h2 class="title-with-strap margin-bottom-20">
                Outras soluções para otimizar a sua Farmácia
              </h2>
              <div class="font-size-24 font-weight-500">
                Se a eﬁciência do seu negócio é importante para si, é importante
                para nós. <br />
                Por isso, sugerimos-lhe também:
              </div>

              <div
                class="produtos-wrapper outros-produtos-wrapper margin-top-50"
              >
                <div
                  v-for="product in related_products"
                  :key="product.id"
                  class="produto-item related"
                >
                  <div v-if="product.name != null && product.id != product_id">
                    <router-link
                      class="links"
                      :key="'/produto/' + product.id"
                      :to="{
                        path:
                          '/produto/' +
                          product.id +
                          '-' +
                          product.name.replaceAll(' ', '-').toLowerCase(),
                      }"
                    >
                      <div class="produto-item-inner-wrapper">
                        <div
                          v-if="product.image == ''"
                          class="imagem-wrapper"
                          :style="{
                            'background-image': 'url(' + imgProd + ')',
                          }"
                        ></div>
                        <div
                          v-if="product.image != ''"
                          class="imagem-wrapper"
                          :style="{
                            'background-image':
                              'url(/assets/fotos_catalogo/products/' +
                              product.image +
                              ')',
                          }"
                        ></div>
                        <div class="conteudo-wrapper">
                          <h4
                            class="produto-nome font-size-20"
                            style="margin-top: 15px"
                          >
                            {{ product.name }}
                          </h4>
                          <span class="texto-topo">{{
                            product_data.area_name
                          }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </section>
    </div>
    <div v-else>
      <div id="produto">
        <p>Este produto foi removido.</p>
      </div>
    </div>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  export default {
    name: "produto",
    components: {
      Header,
      Footer,
    },
    props: ["backdoor"],
    data() {
      return {
        removido: false,
        imgProd: "/assets/imagens/default.png",
        imgFam: "/assets/imagens/default.png",
        imgImg: "/assets/imagens/default.png",
        product_id: this.$route.params.id,
        menu: {},
        product_data: {},
        page_data: {},
        related_products: {},
        product_images: [],
        breadcrums: {},
        loadingStyle: "flex",
        fileURL: "",
        isLogged: window.sessionStorage.user_level,
        n: 0,
        showModal: true,
      };
    },
    computed: {
      documents() {
        let map = [];

        map.push({
          value: this.page_data.link_1,
          label: this.page_data.link_1_name,
        });
        map.push({
          value: this.page_data.link_2,
          label: this.page_data.link_2_name,
        });
        map.push({
          value: this.page_data.link_3,
          label: this.page_data.link_3_name,
        });
        map.push({
          value: this.page_data.link_4,
          label: this.page_data.link_4_name,
        });
        map.push({
          value: this.page_data.link_5,
          label: this.page_data.link_5_name,
        });

        return map.filter(function (e) {
          return e.value != "" && e.label != "";
        });
      },
      documentsAdmin() {
        let map = [];

        map.push({
          value: this.page_data.link_1,
          label: this.page_data.link_1_name,
        });
        map.push({
          value: this.page_data.link_2,
          label: this.page_data.link_2_name,
        });
        map.push({
          value: this.page_data.link_3,
          label: this.page_data.link_3_name,
        });
        map.push({
          value: this.page_data.link_4,
          label: this.page_data.link_4_name,
        });
        map.push({
          value: this.page_data.link_5,
          label: this.page_data.link_5_name,
        });
        map.push({
          value: this.page_data.link_6,
          label: this.page_data.link_6_name,
        });

        map.push({
          value: this.page_data.link_7,
          label: this.page_data.link_7_name,
        });

        return map.filter(function (e) {
          return e.value != "" && e.label != "";
        });
      },

      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.m;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
      produto() {
        return this.page_data;
      },
    },
    methods: {
      openInNewTab() {
        let url = this.fileURL;
        this.fileURL = "";
        if (url == "") {
          return;
        }
        event.preventDefault();
        var win = window.open(
          "/assets/fotos_catalogo/products/" + url,
          "_blank"
        );
        win.focus();
      },
      closeModal() {
        if (this.showModal == true) {
          this.showModal = false;
        } else {
          this.showModal = true;
        }
      },
      addProduct: async function (produto) {
        event.preventDefault();
        this.n += 1;
        if ((await this.checkProdInCart(produto.id)) == true) {
          return false;
        }

        produto.quantity = 1;
        produto.border_color = this.product_data.area_color;

        this.materiaisCarrinho.push(produto);
        let cart = this.materiaisCarrinho;

        window.sessionStorage.setItem("cart", JSON.stringify(cart));

        this.$emit("update-cart");
      },
      async updateCart() {
        this.$emit("update-cart");
      },
      async checkProdInCart(id) {
        let temp = await this.materiaisCarrinho.reduce(function (
          acumulador,
          valorAtual
        ) {
          acumulador.push(valorAtual.id);

          return acumulador;
        },
        []);

        return temp.indexOf(id) > -1;
      },
      analyticsEventDocumentos() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar no ver documentos",
          eventCategory: "conv",
          eventAction: "document",
          eventLabel: "form",
        });
        ga("send", "event", "Clicar no ver documentos", "contact", "form");

        console.log("evento");
      },
      analyticsEventContactos() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar no solíticar contacto",
          eventCategory: "conv",
          eventAction: "contact",
          eventLabel: "form",
        });
        ga("send", "event", "Clicar no solíticar contacto", "contact", "form");

        console.log("evento");
      },
      np: function () {
        let url = this.fileURL;
        this.fileURL = "";
        event.preventDefault();
        var win = window.open(
          "/assets/fotos_catalogo/products/" + url,
          "_blank"
        );
        win.focus();
      },
      collect_page_data: async function () {
        var operation = "products/" + this.product_id;
        console.log(this.product_id);
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;
        console.log(url);
        this.loadingStyle = "flex";

        var product_data = await this.call_api_get(url, headers);
        console.log(product_data.area, "oi");

        if (Object.values(product_data.area).length < 1) {
          this.$router.push("/home");
          return;
        }
        // console.log(product_data.related_products)

        this.product_data = product_data.area[0];
        this.menu = product_data.categories;
        this.related_products = product_data.related_products;
        this.page_data = product_data.product[0];
        this.product_images = product_data.images[0];

        this.breadcrums = {
          area_name: product_data.area[0].area_name,
          area_id: product_data.area[0].area_id,
          category_name: product_data.area[0].category_name,
          category_id: product_data.area[0].category_id,
          family_name: product_data.product[0].family_name,
          family_id: product_data.product[0].g_family_id,
          product_id: product_data.product[0].id,
          product_name: product_data.product[0].name,
        };

        // if(this.page_data.action_sentence == '' || this.page_data.action_sentence == null) {
        //     document.querySelector("#bot-scroll-rel").style.display = "block"
        //     document.querySelector("#top-scroll-pro").style.display = "block"
        // }
        // else {
        //     document.querySelector("#bot-scroll-sec").style.display = "block"
        //     document.querySelector("#top-scroll-sec").style.display = "block"
        // }

        // document.querySelector(".loadingLayer").style.display = "none"

        var color_code = this.product_data.area_color;

        var styleNode = document.createElement("style");
        styleNode.type = "text/css";
        var styleText = document.createTextNode(
          ":root {--cor-principal: " +
            color_code +
            " !important;--cor-1: " +
            color_code +
            " !important;}"
        );
        styleNode.appendChild(styleText);

        document.getElementsByTagName("head")[0].appendChild(styleNode);

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 500);
      },
      call_api_post: async function (url, headers, method) {
        try {
          var response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(headers, null, 2),
          });
          var result = await response.json();
          console.log(result, 1);
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    created() {
      this.collect_page_data();
    },
    watch: {
      "$route.params.id"(newId, oldId) {
        // console.log(newId);
        this.product_id = newId;
        this.collect_page_data();
      },
    },
    mounted: function () {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }
      $(document).ready(function () {
        $(window).scroll(function () {
          if ($(window).height() >= $(document).height() / 2) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Scroll página de produtos",
              eventCategory: "int",
              eventAction: "scroll",
              eventLabel: "product",
            });
            ga(
              "send",
              "event",
              "Scroll página de produtos",
              "scroll",
              "product"
            );

            console.log("evento");
          }
        });
        $(".imagens-wrapper").each(function () {
          var $imagensWrapper = $(this);

          if ($imagensWrapper.children().length > 1) {
            $(this).parent().addClass("swiper-container");
            //Slider
            var mySwiper = new Swiper("main .swiper-container", {
              slidesPerView: 1,
              slidesPerGroup: 1,
              autoplay: true,
              infinite: true,
              arrows: true,
              fadeEffect: {
                crossFade: true,
              },
              pagination: {
                el: ".swiper-pagination",
              },
              navigation: {
                nextEl: ".slider-swiper-button-next",
                prevEl: ".slider-swiper-button-prev",
              },
            });
          }
        });
        $("nav").each(function () {
          var navSlider = new Swiper("nav .menu-container.swiper-container", {
            navigation: {
              nextEl: ".nav-swiper-button-next",
              prevEl: ".nav-swiper-button-prev",
            },
            autoplay: {
              delay: 2500,
              disableOnInteraction: true,
            },
            infinite: true,
            breakpoints: {
              640: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
              },
            },
          });
        });
        $(".produtos-outer-wrapper").each(function () {
          var navSlider = new Swiper(".produtos-outer-wrapper", {
            navigation: {
              nextEl: ".produtos-swiper-button-next",
              prevEl: ".produtos-swiper-button-prev",
            },
            breakpoints: {
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
            },
          });
        });
      });
    },
    updated() {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }

      $(document).ready(function () {
        $(".imagens-wrapper").each(function () {
          var $imagensWrapper = $(this);

          if ($imagensWrapper.children().length > 1) {
            $(this).parent().addClass("swiper-container");
            //Slider
            var mySwiper = new Swiper("main .swiper-container", {
              slidesPerView: 1,
              slidesPerGroup: 1,
              fadeEffect: {
                crossFade: true,
              },
              pagination: {
                el: ".swiper-pagination",
              },
              navigation: {
                nextEl: ".slider-swiper-button-next",
              },
            });
          }
        });
        var menuSlider = new Swiper("footer .menu-container.swiper-container", {
          navigation: {
            nextEl: ".menu-swiper-button-next",
            prevEl: ".menu-swiper-button-prev",
          },
          autoplay: {
            delay: 2500,
            disableOnInteraction: true,
          },
          infinite: true,
          breakpoints: {
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          },
        });
        $("nav").each(function () {
          var navSlider = new Swiper("nav .menu-container.swiper-container", {
            navigation: {
              nextEl: ".nav-swiper-button-next",
              prevEl: ".nav-swiper-button-prev",
            },
            breakpoints: {
              640: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
              },
            },
          });
        });
        $(".produtos-outer-wrapper").each(function () {
          var navSlider = new Swiper(".produtos-outer-wrapper", {
            navigation: {
              nextEl: ".produtos-swiper-button-next",
              prevEl: ".produtos-swiper-button-prev",
            },
            breakpoints: {
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
            },
          });
        });
      });
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  #btn-cart {
    color: #fff;
    background: #ea8822;
    z-index: 0;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;
  }
  #saiba-mais {
    text-decoration: none;
  }
  #btn-cart::after {
    content: "";
    height: 101%;
    position: absolute;
    top: 0;
    right: -4%;
    width: 20px;
    background-color: #ea8822;
    transform: skew(-15deg);
    -webkit-transform: skew(-15deg);
    display: inline-block;
    z-index: -1;
  }
  #btn-cart:hover {
    cursor: pointer;
  }
  #btn-cart .button-txt {
    font-family: inherit;
    color: inherit;
    display: flex;
    align-items: center;
  }
  #cart-icon-prod {
    color: inherit;
    width: 30px;
    margin-left: 10px;
  }
  #second .main-coluna-conteudo.sem-padding {
    padding-bottom: 0;
  }
  #second .main-coluna-conteudo-inner-wrapper {
    height: 100%;
  }
  #second .main-coluna-imagens {
    height: 100%;
  }
  #second .main-coluna-conteudo .buttons-wrapper:not(.btn-50) {
    margin: 20px 0;
  }
  #second .main-coluna-imagens .buttons-wrapper {
    justify-content: center;
    margin-top: 10px;
  }
  .buttons-wrapper.btn-50 {
    height: 50px;
    overflow-y: hidden;
  }
  #falar-connosco {
    z-index: 2;
    margin-right: -3px;
    margin-top: 3px;
    display: inline-block;
    transform: skew(-15deg);
    -webkit-transform: skew(-15deg);
    height: 27.5px;
  }
  #falar-connosco .button-txt {
    color: inherit;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
    margin: 0;
    line-height: 1.5;
    padding-top: 5%;
  }
  #documentos-a-descarregar {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    margin-left: -5px;
    cursor: pointer;
    text-decoration: none;
    padding-left: 10%;
  }

  #documentos-a-descarregar .button-txt {
    height: 50px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    margin-left: -5px;
    cursor: pointer;
    text-decoration: none;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
  }
  #documentos-a-descarregar-login {
    height: 98% !important;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    margin-left: -5px;
    cursor: pointer;
    text-decoration: none;
    padding-left: 10% !important;
  }

  #documentos-a-descarregar-login .button-txt {
    height: 50px !important;
    text-align: center;
    margin-left: -5px;
    cursor: pointer;
    text-decoration: none;
    max-height: 60%;
    transform: skew(15deg);
    -webkit-transform: skew(15deg);
    padding-left: 10% !important;
  }

  #saber-mais {
    display: flex;
    margin-top: 3px;
    color: var(--cor-principal);
    text-decoration: none;
    transform: skew(0deg);
    -webkit-transform: skew(0deg);
  }
  select#documentos-a-descarregar {
  }

  .lista-icone-pq-texto-wrapper {
    width: 35vw;
    margin-top: 20px;
  }
  .select-border {
    border: 3px solid var(--cor-1);
    max-height: 60%;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    display: none;
  }
  .swipe-menu {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }

  main .title-with-strap,
  main h1.title-with-strap,
  main h2.title-with-strap,
  main h3.title-with-strap,
  main h4.title-with-strap,
  main h5.title-with-strap {
    margin-bottom: 10px;
  }

  section main .lista-icone-descricao-wrapper .lista-item .icone-wrapper {
    height: 40px !important;
  }

  section main .lista-icone-descricao-wrapper {
    margin-bottom: 20px;
  }

  .title-with-strap.green-strap::after {
    margin-left: 40%;
  }

  .buttons-wrapper.btn-50 {
    overflow-x: hidden;
  }

  .mini-btn {
    height: 26px;
    width: 26px;
    overflow: hidden;
    background: var(--cor-2);
    color: #fff;
    padding: 10px 10px;
    -webkit-transition-property: width;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    position: fixed;
    line-height: 26px;
    -webkit-box-shadow: 1px 1px 1px 0px rgba(143, 143, 143, 1);
    -moz-box-shadow: 1px 1px 1px 0px rgba(143, 143, 143, 1);
    box-shadow: 1px 1px 1px 0px rgba(143, 143, 143, 1);
    z-index: 99;
  }
  #mini-contactar {
    right: 10px;
    bottom: 15px;
  }
  #mini-adicionar {
    right: 10px;
    bottom: 70px;
  }
  .mini-btn:hover {
    cursor: pointer;
    width: 110px;
    font-size: 15px;
  }
  #mini-contactar i {
    margin-left: 10px;
    font-size: 25px;
  }
  #mini-adicionar img {
    margin-left: 8px;
    width: 36px;
    margin-right: -5px;
  }

  .video-container {
    display: flex;
    background: #000;
  }

  .slider-swiper-button-next {
    background-image: url("/assets/imagens/arrow_next_gray.svg");
    top: calc(22.5vh - 25px);
  }

  @media only screen and (max-width: 1000px) {
    #documentos-a-descarregar {
      width: 40% !important;
      height: 65%;
    }
  }

  @media only screen and (min-width: 1200px) and (orientation: landscape) {
    #falar-connosco {
      height: 100%;
    }
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .video-container {
      display: flex;
      justify-content: center;
      width: 100%;
      background: #000 !important;
      background-size: cover;
    }
    .prod-video {
      max-height: 100%;
      height: 100%;
      align-self: center;
    }

    section main .lista-icone-descricao-wrapper .lista-item {
      width: 100%;
    }
    .produto-item {
      width: calc(100% / 3.1);
    }
    .produtos-wrapper.outros-produtos-wrapper
      .produto-item
      .imagem-wrapper
      .texto-topo {
      left: 0px !important;
    }
    .texto-topo {
      color: var(--cor-1) !important;
    }
    section footer .container-25p:before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: 0;
      width: 50px;
      left: -49px;
      -webkit-clip-path: polygon(50px 0, 0 100%, 100% 100%, 50px 0);
      clip-path: polygon(50px 0, 0 100%, 100% 100%, 50px 0);
      background: #fff;
      z-index: 3;
    }
    .discount-tag {
      max-width: 25px;
      margin-left: 10px;
      align-self: center;
    }
    .seccao main .main-inner-wrapper .main-coluna-conteudo {
      padding-top: 0px !important;
      align-items: flex-start;
    }

    section#produto-wrapper footer {
      height: 23vh;
    }
    section footer .container.white {
      background-color: #fff;
      top: calc(2vh + 1px);
      height: 21vh;
      align-items: center;
    }
    .imagens-wrapper .swiper-slide {
      background-size: auto 100% !important;
      background-color: #ffffff;
    }
    .no-pointer:hover {
      cursor: help;
    }
    #documentos-a-descarregar-login {
      /* min-height: calc(22px + 3.5vh); */
      margin-left: -10px;
    }
    .margin-top-70 {
      margin-top: 60px;
    }
    .produto-item div .links .produto-item-inner-wrapper {
      padding-left: 25px;
      padding-right: 25px;
    }
    .produto-item div .links .imagem-wrapper {
      background-size: cover;
      background-position: center;
      padding-top: 100%;
      border: 3px solid #fff;
    }
    .produto-item div .links h4 {
      margin-bottom: 8px;
      margin-left: 0;
    }
    footer.footer-contactos {
      height: 10vh !important;
    }

    @media only screen and (min-height: 768px) and (orientation: landscape) {
      section#produto-wrapper footer {
        /* height: 22vh; */
        height: 18vh;
      }
      section footer .container.white {
        background-color: #fff;
        top: calc(2vh + 1px);
        /* height: 20vh; */
        height: 16vh;
        align-items: center;
      }
    }
  }

  @media only screen and (min-height: 900px) and (orientation: landscape) {
    #documentos-a-descarregar {
      min-width: calc(260px + 2vh);
    }
    #documentos-a-descarregar-login {
      min-width: calc(260px + 2vh);
    }
    #falar-connosco .button-txt {
      line-height: 1;
    }
  }

  @media only screen and (min-height: 1080px) and (orientation: landscape) {
    .seccao.header-fixo:not(#second) main:not(.listagem-produtos-com-footer) {
      padding-top: calc(128px + 7.35vh) !important;
      height: calc(100vh - 128px - 9.35vh) !important;
    }
  }

  @media only screen and (max-width: 640px) {
    .prod-video {
      max-height: 100%;
      height: auto;
      width: 100%;
      align-self: center;
    }

    .m_link {
      width: 100% !important;
    }

    .related {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateY(-50%) translateX(100vw);
  }

  #popup {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }

  #popup-paragraph {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #show-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    display: inline-block;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 700;
    -webkit-box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
    box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .modal {
    position: fixed;
    width: 13%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 99;
    max-width: 400px;
    background-color: #fff;
    padding: 25px;
  }

  .close-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    color: #000;
    cursor: pointer;
  }

  #intro {
    text-align: center;
  }

  .popup-paragraph,
  .popup-second-title {
    text-align: center;
    font-weight: 200 !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateY(-50%) translateX(100vw);
  }

  .login-popup {
    color: rgb(46, 183, 142);
  }

  .title-with-strap.green-strap::after {
    background-color: rgb(46, 183, 142) !important;
  }

  .button.diagonal.ghost::before {
    border: 1px solid rgb(46, 183, 142);
  }
</style>
