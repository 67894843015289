import { render, staticRenderFns } from "./Produto.vue?vue&type=template&id=d46b2b86&scoped=true&"
import script from "./Produto.vue?vue&type=script&lang=js&"
export * from "./Produto.vue?vue&type=script&lang=js&"
import style0 from "@/assets/style.css?vue&type=style&index=0&lang=css&"
import style1 from "@/assets/produtos.css?vue&type=style&index=1&lang=css&"
import style2 from "@/assets/responsive.css?vue&type=style&index=2&lang=css&"
import style3 from "@/assets/ficheiros/swiper.min.css?vue&type=style&index=3&lang=css&"
import style4 from "@/assets/ficheiros/font-awesome.min.css?vue&type=style&index=4&lang=css&"
import style5 from "./Produto.vue?vue&type=style&index=5&id=d46b2b86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d46b2b86",
  null
  
)

export default component.exports